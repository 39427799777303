import React from 'react';
import PropTypes from 'prop-types';

import Bn from '../../../../components/button';

import './button--merdesable.scss';

/**
 * Button component - brand 1
 *
 * @param {array<string>} extraClasses An array of classes to be set on the button element.
 * @param {string} buttonText The text to be used
 * @return {JSX.Element}
 * @constructor
 */
const Button = (props) => {
  const extraClasses = props.extraClasses.concat('btn--merdesable');

  return (
    <Bn {...props} extraClasses={extraClasses}>{props.children}</Bn>
  );
}

Button.propTypes = {
  extraClasses: PropTypes.array,
  buttonText: PropTypes.string
};

export default Button;
