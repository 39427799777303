import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import './buttton.scss';

const onClick = ({GAenabled, pressedEnabled, setPressed, pressed, toast}) => {
  if (GAenabled) {
    toast("Click pushed to the dataLayer!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  if (pressedEnabled) {
    setPressed(pressed === null ? true : !pressed);
  }
}


/**
 * Button CORE component
 *
 * @param {array<string>} extraClasses An array of classes to be set on the button element.
 * @param {string|JSX.Element} children The text to be used
 * @return {JSX.Element}
 * @constructor
 */
const Button = (
  {
    extraClasses,
    children,
    GAenabled = false,
    pressedEnabled = false,
    toast
  }) => {

  const [pressed, setPressed] = useState(null);

  return (
    <button
      type={`button`}
      onClick={() => {
        onClick({GAenabled, pressedEnabled, setPressed, pressed, toast})
      }}
      aria-pressed={pressed}
      className={classNames('btn', extraClasses)}>{children}</button>
  );
}

Button.propTypes = {
  extraClasses: PropTypes.array,
  children: PropTypes.string
};

export default Button;
