import React, {useEffect, useState} from 'react';
import { Link } from 'gatsby';

import { Container, Row, Col } from 'react-grid-system';

import Joyride from 'react-joyride';


import '../styles/button-page.scss';

import DMButton from '../../components/drayton-manor/button';
import IMButton from '../../components/isla-magica/button';
import MDButton from '../../components/merdesable/button';
import Button from '../../../components/button';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  textAlign: 'center',
  display: 'block'
}


const ButtonsPage = () => {
  let utilityClasses = [];
  const [hitArea, setHitArea] = useState(false);
  const [GAenabled, setGAenabled] = useState(false);
  const [pressedEnabled, setPressedEnabled] = useState(false);
  const [steps, setSteps] = useState([
    {
      target: '#addAccessibilityImprv',
      content: (
        <div>Focused continuous improvement will be carried out on the core set of components.
        These improvements could be for accessibility, performance or visual changes.
          <br /><br />
          When this work is completed it can then be trickled down
        to the sub-brands will very extra little development required.
        <br /><br />Click the button to see how adding larger hit areas and type styles flows
        down to the brand components.</div>),
    },
    {
      target: '#addGASupport',
      content: (
        <div>Because we have this flow of functionality it opens up the doors to feeding down the chain labour intensive features like Google Analytics
          dataLayer creation.
          <br /><br />
          Add interaction pushes to the core components and deploy it to every single project - save you a huge amount of time and money.
          <br /><br />Try clicking the buttons below - then activate this button to see the new GA dataLayer pushes added to buttons below.
        </div>),
    },
    {
      target: '#addMarkup',
      content: (
        <div>Now we have complete control of the component system we can take things even further. Decoupling the content from the presentation
          means we can add add new features, make structural changes and rollout performance improvements with a single development workstream.</div>),
    }
  ]);

  utilityClasses = hitArea ? utilityClasses.concat('core-min-hit-area') : utilityClasses;

  return (
    <main style={pageStyles}>
      <title>Multi brand buttons</title>
      <h1 style={headingStyles}>Multi-brand button demo</h1>
      <ToastContainer />

      <Container style={{textAlign: "center"}}>

        <Row style={{width: "100%", textAlign: "center"}}>
          <Col>Add improvements: </Col>
        </Row>
        <Row align="center">
          <Col>
            <button
              className={'btn btn-utility'}
              id={'addAccessibilityImprv'}
              onClick={() => setHitArea(!hitArea)}
            >Accessibility improvements
            <span className={`status status--${hitArea ? 'on' : 'off'}`}></span>
            </button>
          </Col>
          <Col>
            <button
              className={'btn btn-utility'}
              id={'addGASupport'}
              onClick={() => setGAenabled(!GAenabled)}
            >GA support for button clicks
              <span className={`status status--${GAenabled ? 'on' : 'off'}`}></span>
            </button>
          </Col>
          <Col>
            <button
              className={'btn btn-utility'}
              id={'addMarkup'}
              onClick={() => setPressedEnabled(!pressedEnabled)}
            >Extra accessibility attribute
              <span className={`status status--${pressedEnabled ? 'on' : 'off'}`}></span>
            </button>
          </Col>

        </Row>
      </Container>

      <div className={'core-button-wrapper'}>
        <Button extraClasses={utilityClasses}>Core button</Button>
        <br /><br />
        <p style={{display: 'block'}}>The buttons above add new feature to this core button, the buttons below then inherit the features.</p>
      </div>


      <Container>

        <Row>
          <Col className={'button-wrapper'}>
            <DMButton
              extraClasses={utilityClasses}
              GAenabled={GAenabled}
              pressedEnabled={pressedEnabled}
              toast={toast}
            >Welcome to Drayton Manor</DMButton>
            <p>
              With over 25 rides and attractions, there's so much for Thomas fans to explore on The Island of Sodor at Drayton Manor Park.

              Whether you fancy a trip down Sodor Railway with Thomas, Rosie or Percy or you'd like to fly high with James, Harold and Jeremy, there are plenty of Thomas & Friends themed attractions to explore.
            </p>
          </Col>

          <Col className={'button-wrapper'} style={{backgroundColor: '#17a45a'}}>
            <IMButton
              extraClasses={utilityClasses}
              GAenabled={GAenabled}
              pressedEnabled={pressedEnabled}
              toast={toast}
            >Bienvenido a Isla Magica</IMButton>
            <p>The Isla Mágica theme park has 6 theme areas that will make you travel in time to know the uses and most representative sites of the age of discoveries, the 16th and 17th centuries.

              Explore each of this areas to find its attractions, shows, restaurants, shops and games and you will spend an unforgettable day.</p>
          </Col>

          <Col className={'button-wrapper'} style={{backgroundColor: '#9C6D4A'}}>
            <MDButton
              extraClasses={utilityClasses}
              GAenabled={GAenabled}
              pressedEnabled={pressedEnabled}
              toast={toast}
            >Bienvenue à La Mer De Sable</MDButton>
            <p>De la Vallée du Mississippi au Canyon du Colorado en passant par le Désert d'Arizona, pas besoin d'une machine à remonter le temps pour transporter toute votre tribu à l'époque de la Conquête de l'Ouest !
              Suivez le filon des émotions et créez votre Western Story en famille. </p>
          </Col>
        </Row>

      </Container>
      <Joyride steps={steps}/>


    </main>
  )
}

export default ButtonsPage;
